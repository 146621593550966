<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">
          <vuexy-logo />
        </h2>
      </b-link>

      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="7" class="d-none d-lg-flex align-items-center p-5 z-40">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="5"
        class="d-flex align-items-center auth-bg px-2  pt-lg-2 pr-lg-5 pl-lg-5"
      >
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto my-3">
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1 text-3xl text-center"
          >
            {{ $t("message.login.rheader") }}
          </b-card-title>
          <b-card-text class="mb-2 text-center">
            {{ $t("message.login.rsubHeader") }} 🚀
            <b-button
              style="display: none;"
              id="declenche"
              v-ripple.400="'rgba(0, 207, 232, 0.15)'"
              v-b-modal.modal-primary
              variant="outline-primary"
            >
              primary
            </b-button>

            <b-button
              id="entrepriselist"
              style="display: none;"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-default
              variant="outline-primary"
            >
              Default Modal
            </b-button>

            <b-modal
              id="modal-default"
              hide-footer
              centered
              title="Vos Entreprises"
            >
              <div
                class="row"
                v-for="entreprise in logData.entreprises"
                :key="entreprise.id"
              >
                <div class="col-lg-8 m-auto">
                  <a @click="log(entreprise.code)">
                    <div class="row alert-primary p-1 rounded">
                      <div class="col-lg-8 text-center alert-primary-strong">
                        <span
                          class="h2 text-center font-weight-bold text-indigo"
                          >{{ entreprise.libelle }}</span
                        >
                      </div>
                      <div class="col-lg-4">
                        <img
                          v-if="entreprise.image"
                          :src="entreprise.image"
                          height="30"
                          width="40"
                          alt="logo"
                        />
                        <img
                          v-else
                          src="@/assets/images/logo/logo.png"
                          height="30"
                          width="40"
                          alt="logo"
                        />
                      </div>
                      <hr />
                    </div>
                  </a>
                </div>
              </div>
            </b-modal>

           
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
              <!-- nom -->
              <b-form-group label="" label-for="register-nom">
                <label for="">{{ $t("message.login.input.firstName") }} <span class="text-danger">*</span> </label>
                <validation-provider
                  #default="{ errors }"
                  name="nom"
                  rules="required"
                >
                  <b-form-input
                    id="register-nom"
                    @input="validateNom"
                    v-model="nom"
                    name="register-nom"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Sika"
                  />
                  <small
                    :class="valideNom ? 'block' : 'none'"
                    class="text-danger"
                  >
                    Vous devez renseigner votre nom
                  </small>
                </validation-provider>
              </b-form-group>

              <!-- prenom -->
              <b-form-group label="" label-for="register-prenom">
                <label for=""
                  >{{ $t("message.login.input.lastName") }} <span class="text-danger">*</span>
                </label>
                <validation-provider
                  #default="{ errors }"
                  name="prenom"
                  rules="required"
                >
                  <b-form-input
                    id="register-prenom"
                    @input="validatePrenom"
                    v-model="prenom"
                    name="register-prenom"
                    :state="errors.length > 0 ? false : null"
                    placeholder="John"
                  />
                  <small
                    :class="validePrenom ? 'block' : 'none'"
                    class="text-danger"
                  >
                    Vous devez renseigner votre prénom
                  </small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group label="" label-for="register-email">
                <label for="">{{ $t("message.login.input.email") }} <span class="text-danger">*</span> </label>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    @input="validateEmail"
                    v-model="email"
                    type="email"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@exemple.com"
                  />
                  <small
                    :class="valideEmail ? 'block' : 'none'"
                    class="text-danger"
                  >
                    Veuillez entrer un email valide
                  </small>
                  <small
                    :class="valideEmailExist ? 'block' : 'none'"
                    class="text-danger"
                  >
                    Ce compte existe déjà !
                  </small>
                </validation-provider>
              </b-form-group>
              <!-- <b-form-group>
                                <label for="map">Adresse</label>
                                <vue-google-autocomplete ref="address" id="map" classname="form-control" :country="['ci','ml']" placeholder="Cocody" v-on:placechanged="getAddressData"> </vue-google-autocomplete>
                            </b-form-group> -->
              <label for=""
                >{{ $t("message.login.input.location") }} <span class="text-danger">*</span></label
              >
              <div id="custom-search-input" class="mb-1">
                <div class="input-group">
                  <input
                    id="autocomplete_search"
                    @input="initialize"
                    name="autocomplete_search"
                    type="text"
                    class="form-control"
                    :placeholder="$t('message.login.input.phLocation')"
                  />
                  <input type="hidden" name="lat" />
                  <input type="hidden" name="long" />
                </div>
              </div>
              <!-- contact -->
              <b-form-group label="" label-for="register-contact"  >
                <label for=""
                  >{{ $t("message.login.input.contact") }} <span class="text-danger">*</span>
                </label>
                <validation-provider
                  #default="{ errors }"
                  name="contact"
                  rules="required"
                >
                  <vue-tel-input
                    id="register-contact"
                    @country-changed="changer"
                    @validate="contactEntier($event)"
                    v-model="phone"
                    name="register-contact"
                    :state="errors.length > 0 ? false : null"
                    placeholder="000-000-000-000"
                    class="z-40 relative"
                  />
                  <small
                    :class="valideContact ? 'block' : 'none'"
                    class="text-danger"
                  >
                    Veuillez renseigner votre contact
                  </small>
                  <small
                    :class="valideContactNumber ? 'block' : 'none'"
                    class="text-danger"
                  >
                    Ce numéro de téléphone n'est pas valide
                  </small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group label-for="register-password" label="">
                <label for=""
                  >{{ $t("message.login.input.password") }} <span class="text-danger">*</span>
                </label>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      @input="validatePassword"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      :placeholder="$t('message.login.input.phPassword')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    :class="validePassword ? 'block' : 'none'"
                    class="text-danger"
                  >
                    Le mot de passe est de 8 caractrères minimum
                  </small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  @change="validateStatus"
                  v-model="status"
                  name="checkbox-1"
                 
                >
                  {{ $t("message.login.input.acceptContract") }}
                  <!-- @click="redirection" -->
                  <b-link
                    v-b-modal.modal-login1
                    :class="valideStatus ? 'text-danger' : 'n'"
                    >{{ $t("message.login.generalTerms") }},
                  </b-link>

                  <b-link
                    v-b-modal.modal-login2
                    :class="valideStatus ? 'text-danger' : 'n'"
                    >{{ $t("message.login.privacyPolicy") }},
                  </b-link>

                    <!-- @click="redirection2" -->
                  <b-link
                    v-b-modal.modal-login3
                    :class="valideStatus ? 'text-danger' : 'n'"
                    >{{ $t("message.login.generalTermsSales") }}.</b-link
                  >

                </b-form-checkbox>
              </b-form-group>

              <!-- popup condition d'utilisation -->
              <b-col lg="">
                <b-modal
                  id="modal-login1"
                  title="Conditions générales d'utilisation"
                  ok-only
                  ok-title="Fermer"
                  centered
                  size="lg"
                >
                  <b-template>
                    <div class="container">
                      <div class="row">
                          <!-- <b-card> -->
                            <h1 class="text-center mb-5 pt-5 ">
                              Conditions générales d'utilisation <br />
                              <!-- des sites web <br> -->
                              Informations et mentions légales
                            </h1>
                            <hr />

                            <h4 class="mt-2">
                              Propriété du site ; acceptation des conditions
                              d'utilisation
                            </h4>
                            <p>
                              Apud has gentes, quarum exordiens initium ab
                              Assyriis ad Nili cataractas porrigitur et confinia
                              Blemmyarum, omnes pari sorte sunt bellatores
                              seminudi coloratis sagulis pube tenus amicti,
                              equorum adiumento pernicium graciliumque camelorum
                              per diversa se raptantes, in tranquillis vel
                              turbidis rebus: nec eorum quisquam aliquando
                              stivam adprehendit vel arborem colit aut arva
                              subigendo quaeritat victum, sed errant semper per
                              spatia longe lateque distenta sine lare sine
                              sedibus fixis aut legibus: nec idem perferunt
                              diutius caelum aut tractus unius soli illis umquam
                              placet. Erat autem diritatis eius hoc quoque
                              indicium nec obscurum nec latens, quod ludicris
                              cruentis delectabatur et in circo sex vel septem
                              aliquotiens vetitis certaminibus pugilum vicissim
                              se concidentium perfusorumque sanguine specie ut
                              lucratus ingentia laetabatur.
                            </p>

                            <h4 class="mt-3">Contenu</h4>
                            <p>
                              Apud has gentes, quarum exordiens initium ab
                              Assyriis ad Nili cataractas porrigitur et confinia
                              Blemmyarum, omnes pari sorte sunt bellatores
                              seminudi coloratis sagulis pube tenus amicti,
                              equorum adiumento pernicium graciliumque camelorum
                              per diversa se raptantes, in tranquillis vel
                              turbidis rebus: nec eorum quisquam aliquando
                              stivam adprehendit vel arborem colit aut arva
                              subigendo quaeritat victum, sed errant semper per
                              spatia longe lateque distenta sine lare sine
                              sedibus fixis aut legibus: nec idem perferunt
                              diutius caelum aut tractus unius soli illis umquam
                              placet. Erat autem diritatis eius hoc quoque
                              indicium nec obscurum nec latens, quod ludicris
                              cruentis delectabatur et in circo sex vel septem
                              aliquotiens vetitis certaminibus pugilum vicissim
                              se concidentium perfusorumque sanguine specie ut
                              lucratus ingentia laetabatur.
                            </p>
                            <p>
                              Apud has gentes, quarum exordiens initium ab
                              Assyriis ad Nili cataractas porrigitur et confinia
                              Blemmyarum, omnes pari sorte sunt bellatores
                              seminudi coloratis sagulis pube tenus amicti,
                              equorum adiumento pernicium graciliumque camelorum
                              per diversa se raptantes, in tranquillis vel
                              turbidis rebus: nec eorum quisquam aliquando
                              stivam adprehendit vel arborem colit aut arva
                              subigendo quaeritat victum, sed errant semper per
                              spatia longe lateque distenta sine lare sine
                              sedibus fixis aut legibus: nec idem perferunt
                              diutius caelum aut tractus unius soli illis umquam
                              placet. Erat autem diritatis eius hoc quoque
                              indicium nec obscurum nec latens, quod ludicris
                              cruentis delectabatur et in circo sex vel septem
                              aliquotiens vetitis certaminibus pugilum vicissim
                              se concidentium perfusorumque sanguine specie ut
                              lucratus ingentia laetabatur.
                            </p>

                            <p>
                              Apud has gentes, quarum exordiens initium ab
                              Assyriis ad Nili cataractas porrigitur et confinia
                              Blemmyarum, omnes pari sorte sunt bellatores
                              seminudi coloratis sagulis pube tenus amicti,
                              equorum adiumento pernicium graciliumque camelorum
                              per diversa se raptantes, in tranquillis vel
                              turbidis rebus: nec eorum quisquam aliquando
                              stivam adprehendit vel arborem colit aut arva
                              subigendo quaeritat victum, sed errant semper per
                              spatia longe lateque distenta sine lare sine
                              sedibus fixis aut legibus: nec idem perferunt
                              diutius caelum aut tractus unius soli illis umquam
                              placet. Erat autem diritatis eius hoc quoque
                              indicium nec obscurum nec latens, quod ludicris
                              cruentis delectabatur et in circo sex vel septem
                              aliquotiens vetitis certaminibus pugilum vicissim
                              se concidentium perfusorumque sanguine specie ut
                              lucratus ingentia laetabatur.
                            </p>

                            <h4 class="mt-2">Utilisation de l'application</h4>
                            <p>
                              Apud has gentes, quarum exordiens initium ab
                              Assyriis ad Nili cataractas porrigitur et confinia
                              Blemmyarum, omnes pari sorte sunt bellatores
                              seminudi coloratis sagulis pube tenus amicti,
                              equorum adiumento pernicium graciliumque camelorum
                              per diversa se raptantes, in tranquillis vel
                              turbidis rebus: nec eorum quisquam aliquando
                              stivam adprehendit vel arborem colit aut arva
                              subigendo quaeritat victum, sed errant semper per
                              spatia longe lateque distenta sine lare sine
                              sedibus fixis aut legibus: nec idem perferunt
                              diutius caelum aut tractus unius soli illis umquam
                              placet. Erat autem diritatis eius hoc quoque
                              indicium nec obscurum nec latens, quod ludicris
                              cruentis delectabatur et in circo sex vel septem
                              aliquotiens vetitis certaminibus pugilum vicissim
                              se concidentium perfusorumque sanguine specie ut
                              lucratus ingentia laetabatur.
                            </p>

                            <h4 class="mt-2">
                              Achats ; autres conditions générales
                            </h4>

                            <p>
                              Apud has gentes, quarum exordiens initium ab
                              Assyriis ad Nili cataractas porrigitur et confinia
                              Blemmyarum, omnes pari sorte sunt bellatores
                              seminudi coloratis sagulis pube tenus amicti,
                              equorum adiumento pernicium graciliumque camelorum
                              per diversa se raptantes, in tranquillis vel
                              turbidis rebus: nec eorum quisquam aliquando
                              stivam adprehendit vel arborem colit aut arva
                              subigendo quaeritat victum, sed errant semper per
                              spatia longe lateque distenta sine lare sine
                              sedibus fixis aut legibus: nec idem perferunt
                              diutius caelum aut tractus unius soli illis umquam
                              placet. Erat autem diritatis eius hoc quoque
                              indicium nec obscurum nec latens, quod ludicris
                              cruentis delectabatur et in circo sex vel septem
                              aliquotiens vetitis certaminibus pugilum vicissim
                              se concidentium perfusorumque sanguine specie ut
                              lucratus ingentia laetabatur.
                            </p>

                            <p>
                              Apud has gentes, quarum exordiens initium ab
                              Assyriis ad Nili cataractas porrigitur et confinia
                              Blemmyarum, omnes pari sorte sunt bellatores
                              seminudi coloratis sagulis pube tenus amicti,
                              equorum adiumento pernicium graciliumque camelorum
                              per diversa se raptantes, in tranquillis vel
                              turbidis rebus: nec eorum quisquam aliquando
                              stivam adprehendit vel arborem colit aut arva
                              subigendo quaeritat victum, sed errant semper per
                              spatia longe lateque distenta sine lare sine
                              sedibus fixis aut legibus: nec idem perferunt
                              diutius caelum aut tractus unius soli illis umquam
                              placet. Erat autem diritatis eius hoc quoque
                              indicium nec obscurum nec latens, quod ludicris
                              cruentis delectabatur et in circo sex vel septem
                              aliquotiens vetitis certaminibus pugilum vicissim
                              se concidentium perfusorumque sanguine specie ut
                              lucratus ingentia laetabatur.
                            </p>

                            <h4 class="mt-2">
                              Comptes, mots de passe et sécurité
                            </h4>
                            <p>
                              Apud has gentes, quarum exordiens initium ab
                              Assyriis ad Nili cataractas porrigitur et confinia
                              Blemmyarum, omnes pari sorte sunt bellatores
                              seminudi coloratis sagulis pube tenus amicti,
                              equorum adiumento pernicium graciliumque camelorum
                              per diversa se raptantes, in tranquillis vel
                              turbidis rebus: nec eorum quisquam aliquando
                              stivam adprehendit vel arborem colit aut arva
                              subigendo quaeritat victum, sed errant semper per
                              spatia longe lateque distenta sine lare sine
                              sedibus fixis aut legibus: nec idem perferunt
                              diutius caelum aut tractus unius soli illis umquam
                              placet. Erat autem diritatis eius hoc quoque
                              indicium nec obscurum nec latens, quod ludicris
                              cruentis delectabatur et in circo sex vel septem
                              aliquotiens vetitis certaminibus pugilum vicissim
                              se concidentium perfusorumque sanguine specie ut
                              lucratus ingentia laetabatur.
                            </p>

                            <h4 class="mt-2">Confidentialité</h4>
                            <p>
                              Apud has gentes, quarum exordiens initium ab
                              Assyriis ad Nili cataractas porrigitur et confinia
                              Blemmyarum, omnes pari sorte sunt bellatores
                              seminudi coloratis sagulis pube tenus amicti,
                              equorum adiumento pernicium graciliumque camelorum
                              per diversa se raptantes, in tranquillis vel
                              turbidis rebus: nec eorum quisquam aliquando
                              stivam adprehendit vel arborem colit aut arva
                              subigendo quaeritat victum, sed errant semper per
                              spatia longe lateque distenta sine lare sine
                              sedibus fixis aut legibus: nec idem perferunt
                              diutius caelum aut tractus unius soli illis umquam
                              placet. Erat autem diritatis eius hoc quoque
                              indicium nec obscurum nec latens, quod ludicris
                              cruentis delectabatur et in circo sex vel septem
                              aliquotiens vetitis certaminibus pugilum vicissim
                              se concidentium perfusorumque sanguine specie ut
                              lucratus ingentia laetabatur.
                            </p>
                          <!-- </b-card> -->
                      </div>
                    </div>
                    
                    <div>
                      <footer class="container">
                       COPYRIGHT  © {{ new Date().getFullYear() }} <a href="//qenium.com">Qenium,</a> Tous
                      droits réservés.<br />
                    </footer>
                    </div>
                    
                  </b-template>
                </b-modal>
              </b-col>
              <!-- end popup condition d'utilisation -->


              <!-- popup politique de confidentialité -->
              <b-col lg="">
                <b-modal
                  id="modal-login2"                
                  title="Politique de confidentialité"
                  ok-only
                  ok-title="Fermer"
                  centered
                  size="lg"
                >
                  <b-template>
                    <div class="container">
                      <div
                        class="position-relative table-responsive table-base"
                      >
                        <!-- <b-card> -->
                        <h1 class="text-center mt-4 mb-4">
                          <strong>Politique de confidentialité</strong>
                        </h1>
                        <hr />

                        La politique de confidentialité de EDIQIA, ainsi que nos
                        produits et services connexes, décrit les informations
                        que nous recueillons auprès de vous dans le cadre du
                        fonctionnement normal de notre site et de notre
                        plateforme, ainsi que les moyens par lesquels nous
                        sécurisons ces informations. Il décrit également vos
                        choix concernant l’utilisation, l’accès et la correction
                        de vos informations personnelles.

                        <br />

                        En acceptant la politique de confidentialité lors de
                        l’inscription ou en visitant et en utilisant le site,
                        vous consentez expressément à la collecte, à
                        l’utilisation et à la divulgation de vos informations
                        personnelles conformément à la présente politique de
                        confidentialité. Cette politique de confidentialité est
                        incorporée et soumise à nos conditions d’utilisation.

                        <br />

                        Dans la présente politique de confidentialité, nous
                        utilisons les termes suivants pour décrire différents
                        types de visiteurs sur notre site. Nos clients
                        s’appellent des « abonnés » et les clients de nos
                        abonnés s’appellent des « clients». Nous utilisons le
                        mot « utilisateurs » pour décrire les personnes à qui
                        les abonnés donnent un accès «personnel» sur le site.

                        <h4 class="mt-2">
                          <strong>
                            Quelles informations collectons-nous ?</strong
                          >
                        </h4>
                        <p>
                          Notre objectif premier en collectant des informations
                          personnelles vous concernant est de vous fournir une
                          expérience sûre, fluide, efficace et personnalisée.
                          Cela nous permet de fournir des services et des
                          fonctionnalités qui répondent le plus probablement à
                          vos besoins, et de personnaliser notre service pour
                          rendre votre expérience plus sûre et plus facile. Nous
                          collectons uniquement les informations personnelles
                          vous concernant que nous considérons nécessaires pour
                          atteindre cet objectif. En général, vous pouvez
                          naviguer sur le site sans nous dire qui vous êtes ni
                          révéler des informations personnelles vous concernant.
                          Une fois que vous devenez un utilisateur, nous vous
                          demandons de fournir diverses informations de contact
                          et d’identité, des informations de facturation et
                          d’autres informations personnelles, comme indiqué sur
                          les formulaires correspondants sur le site. <br />
                          Dans la mesure du possible, sur ces formulaires, nous
                          indiquons quels champs sont obligatoires et lesquels
                          sont facultatifs. Vous avez toujours la possibilité de
                          ne pas fournir d’informations en choisissant de ne pas
                          devenir un utilisateur ou en n’utilisant pas la
                          fonctionnalité particulière du site pour laquelle les
                          informations sont collectées. Nous suivons également
                          automatiquement certaines informations vous concernant
                          en fonction de votre comportement sur notre site et
                          les stockons dans des fichiers journaux. Nous
                          utilisons ces informations pour effectuer des
                          recherches internes sur les données démographiques,
                          les intérêts et le comportement de nos utilisateurs
                          afin de mieux comprendre, protéger et vous servir,
                          ainsi que notre communauté. Ces informations peuvent
                          inclure l’URL d’origine, l’adresse URL que vous allez
                          utiliser ensuite, les informations relatives au
                          navigateur de votre ordinateur et votre adresse IP.
                          <br />
                          Nous pouvons fournir les adresses IP des clients aux
                          intermédiaires de paiement au cours du processus de
                          paiement. Des technologies telles que les cookies et
                          les technologies similaires sont utilisées par EDIQIA.
                          Ces technologies sont utilisées pour analyser les
                          tendances, administrer le site, suivre les mouvements
                          des utilisateurs sur le site et recueillir des
                          informations démographiques sur l’ensemble de notre
                          base d’utilisateurs. <br />
                          Nous utilisons des cookies pour mémoriser les
                          paramètres des utilisateurs, stocker les adresses de
                          connexion, authentifier les utilisateurs et stocker
                          des données d’analyse. Les utilisateurs peuvent
                          contrôler l’utilisation de cookies au niveau du
                          navigateur. Si vous refusez les cookies, vous pouvez
                          toujours utiliser notre site, mais votre capacité à
                          utiliser certaines fonctionnalités ou zones de notre
                          site peut être limitée.
                        </p>

                        <h4 class="mt-2">
                          <strong
                            >Comment nous utilisons vos informations</strong
                          >
                        </h4>
                        <p>
                          Nous utilisons les informations personnelles vous
                          concernant dans le fichier que nous gérons, ainsi que
                          d’autres informations que nous obtenons de vos
                          activités actuelles et passées sur le site, pour vous
                          fournir les services proposés par le site. résoudre
                          les conflits de service et de facturation; résoudre
                          les problèmes; facturer les montants qui vous sont
                          dus; mesurer l’intérêt des consommateurs pour nos
                          produits et services, vous informer sur les offres,
                          produits, services, événements et mises à jour en
                          ligne et hors ligne; vous fournir des informations
                          qui, dans certains cas, sont pertinentes par rapport à
                          vos intérêts, telles que les actualités sur les
                          produits; personnaliser votre expérience; nous
                          détecter et nous protéger contre les erreurs, les
                          fraudes et autres activités criminelles; appliquer nos
                          conditions d’utilisation ; vous fournir des messages
                          système ou administratifs, et de la manière décrite à
                          vous au moment de la collecte. À l’occasion, nous
                          utilisons une adresse électronique ou d’autres
                          informations de contact pour contacter nos
                          utilisateurs afin de leur demander leur avis sur nos
                          services, de leur transmettre des informations sur les
                          médias et même de les inviter à un dîner. <br />
                        </p>

                        <p>
                          Nous pouvons également utiliser les informations
                          personnelles vous concernant pour améliorer nos
                          efforts de marketing et de promotion, pour analyser
                          l’utilisation du Site, pour améliorer notre contenu et
                          nos offres de produits, et pour personnaliser le
                          contenu, la présentation et les services du Site. Ces
                          utilisations améliorent le site et le personnalisent
                          mieux pour répondre à vos besoins, afin de vous
                          fournir une expérience fluide, efficace, sûre et
                          personnalisée lors de l’utilisation du Site. Si vous
                          souhaitez vous abonner à nos newsletters, nous
                          utiliserons votre nom et votre adresse email pour vous
                          les envoyer. Par respect pour votre vie privée, nous
                          vous proposons un moyen de vous désabonner par
                          courrier électronique. Si vous souhaitez ne plus
                          recevoir nos newsletters, vous pouvez mettre à jour
                          les paramètres de votre compte, suivre les
                          instructions figurant dans l’e-mail ou vous pouvez
                          nous contacter notre support.
                        </p>

                        <h4 class="mt-2">
                          <strong>Divulgation de vos informations</strong>
                        </h4>

                        Nous partagerons ou divulguerons vos informations
                        personnelles à des tiers uniquement de la manière
                        décrite dans la présente politique de confidentialité,
                        notamment : <br />

                        <p>
                          <strong>Données agrégées :</strong> Nous agrégeons les
                          informations de vente, y compris (sans toutefois s’y
                          limiter) le type de secteur, le nombre de factures
                          envoyées, le montant moyen des factures, la méthode
                          d’envoi des factures, le pourcentage payé en ligne,
                          les montants des ventes et les ventes moyennes par
                          client. manière aux abonnés. Cependant, dans ces
                          situations, nous ne divulguons aucune information qui
                          pourrait être utilisée pour vous identifier
                          personnellement. <br />
                        </p>

                        <p>
                          <strong
                            >Demandes juridiques et transitions commerciales
                            :</strong
                          >
                          Dans certaines situations, EDIQIA peut être tenu de
                          divulguer des données à caractère personnel en réponse
                          à des demandes licites des autorités publiques,
                          notamment pour répondre à des exigences de sécurité
                          nationale ou d’application de la loi. EDIQIA peut
                          divulguer vos informations personnelles à toute
                          autorité gouvernementale dans le cadre d’une enquête
                          afin de déterminer si nous nous conformons à toute
                          loi, règle ou réglementation applicable (y compris aux
                          lois, règles et réglementations en matière de
                          protection de la vie privée), en réponse à un tribunal
                          une ordonnance, une assignation à comparaître, une
                          demande en communication préalable ou toute autre
                          procédure judiciaire ou administrative légale, tel que
                          requis ou autorisé par toute loi, règle ou
                          réglementation applicable, et de bonne foi, pour
                          protéger ou défendre les droits ou la propriété de:
                          EDIQIA et d’autres utilisateurs et si EDIQIA est
                          impliqué dans une fusion, une acquisition ou la vente
                          de tout ou partie de ses actifs, vous serez averti par
                          e-mail et / ou par notification visible sur notre site
                          Web de tout changement de propriétaire. ou
                          l’utilisation de vos informations personnelles, ainsi
                          que de vos choix en ce qui concerne vos informations
                          personnelles.
                        </p>

                        <h4 class="mt-2">
                          <strong
                            >Votre utilisation des informations d’autres
                            personnes</strong
                          >
                        </h4>
                        <p>
                          Afin de faciliter les services fournis par le site, le
                          site vous permet dans certaines circonstances de
                          donner aux autres utilisateurs un accès limité aux
                          informations personnelles d’autres personnes. Par
                          exemple, si vous êtes un abonné, vous pouvez utiliser
                          le site pour donner accès à un membre du personnel aux
                          informations personnelles de vos clients. En adhérant
                          à nos conditions d’utilisation , vous acceptez que, en
                          ce qui concerne les informations personnelles des
                          autres personnes que vous collectez, utilisez et
                          divulguez sur le site, vous disposez de tous les
                          consentements et droits nécessaires pour collecter,
                          utiliser et divulguer ces informations de la manière
                          décrite dans cette politique de confidentialité de
                          temps en temps, et vous acceptez que l’indemnité que
                          vous nous donnez dans les conditions d’utilisation
                          s’applique à toute non-conformité de votre part avec
                          ce qui précède.Si vous choisissez d’utiliser notre
                          service de référence pour informer un ami de notre
                          site, nous vous demanderons le nom et l’adresse
                          électronique de votre ami. Nous enverrons
                          automatiquement à votre ami un courrier électronique
                          unique l’invitant à visiter le site. EDIQIA stocke ces
                          informations dans le seul but d’envoyer ce courrier
                          électronique ponctuel et de suivre le succès de notre
                          programme de parrainage. Votre ami peut nous contacter
                          notre support pour nous demander de supprimer ces
                          informations de notre base de données.
                        </p>

                        <h4>
                          <strong>
                            <strong
                              >Conditions d’utilisation des API Google
                            </strong>
                          </strong>
                        </h4>
                        <p>
                          Les utilisateurs disposant d’un compte Gmail, ont la
                          possibilité de le connecter avec leur compte EDIQIA.
                          Cette connexion leur permet d’envoyer des courriels
                          directement depuis la plateforme avec leur adresse
                          professionnelle, évitant ainsi des allers / retours
                          entre le logiciel de gestion et le logiciel de
                          messagerie. Outre la clé cryptée fournie par Google au
                          moment la synchronisation de votre compte Gmail avec
                          EDIQIA, aucune donnée du compte Google n’est stockée
                          ou utilisée par EDIQIA. Les données de nos
                          utilisateurs en général et ceux provenant de Google,
                          en particulier, ne sont en aucun cas partagées avec
                          des tiers. Avant chaque envoi de courriel via la
                          plateforme, EDIQIA vérifie la validité de clé cryptée
                          auprès de Google ainsi que le profil Google de
                          l’utilisateur connecté. Si les deux vérifications sont
                          positives, EDIQIA permet l’envoi de courriel via le
                          compte Gmail de l’utilisateur. A tout moment,
                          l’utilisateur a la possibilité de déconnecter son
                          compte Gmail de EDIQIA. Cela se fait dans les «
                          Réglages », section « Email », onglet « SMTP ». EDIQIA
                          permet cette connexion pour améliorer la productivité
                          de ses utilisateurs.
                        </p>

                        <h4><strong>Sécurité </strong></h4>

                        <P>
                          EDIQIA s’efforce de protéger vos informations
                          personnelles. Pour la sécurité des transactions, nous
                          utilisons le protocole SSL (Secure Sockets Layer), qui
                          chiffre toutes les informations, telles que les
                          informations de facturation, que vous nous envoyez par
                          voie électronique. Le processus de cryptage protège
                          vos informations en les brouillant avant de nous les
                          envoyer de votre ordinateur. Une fois que EDIQIA a
                          reçu votre transmission, nous déployons des efforts
                          raisonnables sur le plan commercial pour assurer sa
                          sécurité sur notre système. Malheureusement, aucune
                          transmission de données sur Internet ne peut être
                          sécurisée à 100%. Par conséquent, bien que nous nous
                          efforcions de protéger vos informations personnelles,
                          nous ne pouvons garantir la sécurité des informations
                          que vous nous transmettez. De plus amples informations
                          sur la sécurité sont disponibles sur le site et dans
                          nos conditions d’utilisation . Si vous avez des
                          questions sur la sécurité de vos informations
                          personnelles, vous pouvez nous contacter notre
                          support.
                        </P>

                        <h4>
                          <strong
                            >Correction et mise à jour de vos informations
                            personnelles</strong
                          >
                        </h4>

                        <p>
                          Sur demande écrite, EDIQIA vous fournira des
                          informations sur les informations personnelles dont
                          nous disposons à votre sujet. Pour réviser, supprimer
                          et mettre à jour vos informations personnelles afin de
                          vous assurer qu’elles sont exactes, vous pouvez vous
                          connecter à votre compte pour effectuer les
                          modifications ou nous contacter notre support . Nous
                          répondrons à votre demande dans un délai raisonnable.
                          Il se peut que nous vous demandions de plus amples
                          informations pour nous aider à répondre à votre
                          demande, notamment en demandant une pièce d’identité..
                        </p>

                        <h4><strong>Suppression de compte</strong></h4>
                        <p>
                          Lorsqu’un client choisit de fermer un compte, nous
                          allons supprimer vos informations de nos systèmes de
                          marketing et de facturation. Cela garantira qu’il n’y
                          aura plus de mailings ou de factures dirigés vers
                          l’utilisateur annulé.
                        </p>

                        <h4 class="mt-2">
                          <strong> La conservation des données</strong>
                        </h4>

                        <p>
                          Nous conserverons vos informations aussi longtemps que
                          votre compte est actif ou nécessaire pour vous fournir
                          des services. Nous conserverons et utiliserons vos
                          informations si nécessaire pour nous conformer à nos
                          obligations légales, résoudre les litiges et faire
                          respecter nos accords. cette période de rétention peut
                          dépasser le point auquel vous fermez votre compte.
                        </p>

                        <h4>
                          <strong
                            >Informations de politique supplémentaires</strong
                          >
                        </h4>
                        <p>
                          Invitations: vous avez la possibilité d’inviter
                          d’autres personnes à travailler avec vous via votre
                          compte EDIQIA . Pour ce faire, EDIQIA vous demande
                          d’importer ou de saisir manuellement les adresses
                          électroniques de vos contacts. Lorsque vous le
                          dirigez, nous leur envoyons une invitation en votre
                          nom ou d’autres avis reflétant les modifications que
                          vous apportez à leur statut sur votre compte. Si vous
                          cliquez sur un lien vers un site tiers, vous quitterez
                          le site EDIQIA et accéderez au site que vous avez
                          sélectionné. Si vous choisissez d’utiliser un produit
                          ou un service tiers avec EDIQIA, vous concluez
                          vous-même un contrat de licence avec le tiers pour
                          l’utilisation de leur produit ou service et pour
                          l’utilisation de vos données. Parce que nous ne
                          pouvons pas contrôler les activités de tiers, nous ne
                          pouvons être tenus responsables de l’utilisation de
                          vos informations personnelles par ces tiers, et nous
                          ne pouvons garantir qu’ils respecteront les mêmes
                          pratiques de confidentialité que EDIQIA. Nous vous
                          encourageons à consulter les politiques de
                          confidentialité de tout autre fournisseur de services
                          auquel vous demandez des services. <br />
                          Si vous visitez un site Web tiers lié à un site
                          EDIQIA, vous devez lire la politique de
                          confidentialité de ce site avant de fournir des
                          informations personnelles. Avec votre consentement,
                          nous pouvons publier votre témoignage avec votre nom.
                          Si vous souhaitez que votre témoignage soit supprimé,
                          veuillez contacter notre support. Notre site Web
                          comprend des fonctionnalités de médias sociaux, telles
                          que les boutons Facebook et Twitter, ainsi que des
                          widgets, tels que le bouton Partager ce bouton ou des
                          mini-programmes interactifs exécutés sur notre site.
                          Ces fonctionnalités peuvent collecter votre adresse
                          IP, la page que vous visitez sur notre site, et
                          définir un cookie pour permettre à la fonctionnalité
                          de fonctionner correctement. Les fonctionnalités et
                          les widgets de médias sociaux sont hébergés par un
                          tiers ou directement sur notre site. Vos interactions
                          avec ces fonctionnalités sont régies par la politique
                          de confidentialité de l’entreprise qui les fournit. Si
                          vous avez des questions ou des plaintes concernant
                          notre politique de confidentialité ou nos pratiques,
                          veuillez contacter notre support.
                        </p>

                        <h4>
                          <strong
                            >Notification de modifications de la déclaration de
                            confidentialité</strong
                          >
                        </h4>
                        <p>
                          Nous pouvons mettre à jour cette déclaration de
                          confidentialité pour refléter les modifications
                          apportées à nos pratiques en matière d’information. Si
                          nous apportons des modifications importantes, nous
                          vous en informerons par e-mail (envoyé à l’adresse
                          e-mail indiquée dans votre compte) ou au moyen d’un
                          avis affiché sur ce site avant l’entrée en vigueur de
                          la modification. Nous vous encourageons à consulter
                          périodiquement cette page pour obtenir les
                          informations les plus récentes sur nos pratiques de
                          confidentialité.
                        </p>

                        <h4><strong> Des questions?</strong></h4>
                        <p>
                          Notre objectif est de rendre nos pratiques de
                          confidentialité faciles à comprendre. Si vous avez des
                          questions, des inquiétudes ou si vous souhaitez des
                          informations plus détaillées, veuillez contacter notre
                          support.
                        </p>
                      </div>
                    </div>

                    <div>
                      <footer class="container">
                       COPYRIGHT  © {{ new Date().getFullYear() }} <a href="//qenium.com">Qenium,</a> Tous
                      droits réservés.<br />
                    </footer>
                    </div>
                  </b-template>
                </b-modal>
              </b-col>
              <!-- end popup politique de confidentialité -->



              <!-- popup condition génerale de vente  -->
              <b-col lg="">
                <b-modal
                  id="modal-login3"
                  title="Condition génerale de vente"
                  ok-only
                  ok-title="Fermer"
                  centered
                  size="lg"
                >
                  <b-template>
                    <div class="container">
                    <h1 class="text-center mb-4 mt-4">
                        Conditions Générales de Vente
                    </h1>
                    <hr />
                    Par sa souscription au Service EDIQIA, le Client marque son accord quant à l’application des
                    Conditions Générales, dénommées ci-après « Conditions Générales » à la relation contractuelle avec
                    QENIUM, à l’exclusion de toutes autres conditions générales, en ce compris celles du Client.
                    Sauf en cas de modification des Conditions Générales, régis par l’article 12.4 ci-après, toute
                    modification, tout changement requièrent la forme écrite signée par un représentant de chaque partie
                    à fin de validité et ne sont applicables que de manière spécifique à l’engagement au vu duquel ils
                    sont convenus, à l’exclusion de tout autre.
                    Le Client reconnait contracter dans le cadre des présentes en qualité de professionnel, et non de
                    consommateur.
                    Dans les Conditions Générales et dans le Contrat Client, les mots ci-après ont la définition
                    suivante lorsqu’ils commencent par une majuscule :

                    <table class="table table-bordered mt-4 mb-3">
                        <tbody>

                            <tr>
                                <!-- <th scope="row">3</th> -->
                                <td colspan="2" class="table-active">Client</td>
                                <td>
                                    Toute personne physique ou morale contractant avec QENIUM en vue de la fourniture du
                                    Service EDIQIA pour la gestion de son entreprise, peu importe les modules utilisés
                                    (par exemple module CRM, gestion de projets ou recrutement)
                                </td>
                            </tr>

                            <tr>
                                <!-- <th scope="row">3</th> -->
                                <td colspan="2" class="table-active">Compte EDIQIA</td>
                                <td>Le compte ouvert au nom du Client sur le Site EDIQIA et lui permettant, notamment,
                                    la configuration de ses paramètres d’utilisation et la gestion de ses transactions.
                                </td>
                            </tr>

                            <tr>
                                <!-- <th scope="row">3</th> -->
                                <td colspan="2" class="table-active">Contrat Client</td>
                                <td>La Proposition de Contrat Client émise par QENIUM telle qu’acceptée en tous ses
                                    termes par le Client et conduisant à l’activation par QENIUM du Compte EDIQIA de ce
                                    Client ou (ii) tout autre document contractuel, y compris ses annexes, acceptées par
                                    QENIUM et le Client régissant l’utilisation du Service EDIQIA.
                                    Sauf mention expresse contraire, les Conditions Générales sont applicables à tout
                                    Contrat Client.
                                </td>
                            </tr>

                            <tr>
                                <!-- <th scope="row">3</th> -->
                                <td colspan="2" class="table-active">Coordonnées du client</td>
                                <td>Données introduites par le Client dans son Compte EDIQIA comprenant notamment les
                                    adresses postale et électronique utilisées par QENIUM pour les communications avec
                                    le Client.</td>
                            </tr>

                            <tr>
                                <!-- <th scope="row">3</th> -->
                                <td colspan="2" class="table-active">Logiciels</td>
                                <td>Logiciels Les logiciels visés à l’article 7 des Conditions Générales. Cela concerne
                                    tout logiciel désigné en tant que tel par QENIUM ou un partenaire commercial de
                                    QENIUM, et également les dénominations commerciales utilisées pour les produits et
                                    services fournis, les marques déposées et autres dénominations y attachées
                                </td>
                            </tr>

                            <tr>
                                <!-- <th scope="row">3</th> -->
                                <td colspan="2" class="table-active">QENIUM</td>
                                <td>QENIUM est la société qui fournit le Service EDIQIA, désignée également comme «
                                    l’autre partie » dans le Contrat Client.</td>
                            </tr>

                            <tr>
                                <!-- <th scope="row">3</th> -->
                                <td colspan="2" class="table-active">Proposition de Contrat Client</td>
                                <td>Tout document permettant la souscription au Service EDIQIA comportant mention du
                                    service et des prix adressé par e-mail ou toute autre forme au Client par QENIUM,
                                    téléchargé par le Client, daté et signé par celui-ci.</td>
                            </tr>

                            <tr>
                                <!-- <th scope="row">3</th> -->
                                <td colspan="2" class="table-active">Service EDIQIA</td>
                                <td>Le service décrit à l’article 1 des Conditions Générales et dont la dénomination
                                    exacte est déterminée par QENIUM.</td>
                            </tr>

                            <tr>
                                <!-- <th scope="row">3</th> -->
                                <td colspan="2" class="table-active">Site EDIQIA</td>
                                <td>Le site Internet accessible à l’adresse www.ediqia.com</td>
                            </tr>

                            <tr>
                                <!-- <th scope="row">3</th> -->
                                <td colspan="2" class="table-active">Tiers</td>
                                <td>Toute personne physique ou morale autre que QENIUM, ses préposés, le Client et les
                                    Utilisateurs du Client</td>
                            </tr>

                            <tr>
                                <!-- <th scope="row">3</th> -->
                                <td colspan="2" class="table-active">Utilisateurs du Client</td>
                                <td>Les préposés du Client pouvant être amené à utiliser le Service EDIQIA ou qui
                                    pourraient être concernés par sa mise à disposition</td>
                            </tr>

                            <tr>
                                <!-- <th scope="row">3</th> -->
                                <td colspan="2" class="table-active">Sous domaine</td>
                                <td>Identifiant unique pour chaque Compte EDIQIA, qui combiné avec une adresse email et
                                    un mot de passe constituent un crédentiel autorisant l’accès au site EDIQIA.
                                    Pour les Comptes EDIQIA, plusieurs identifiants peuvent être liés au même Sous
                                    domaine permettant l’accès à plusieurs utilisateurs.

                                </td>

                            </tr>
                        </tbody>
                    </table>
                    <h4 class="mt-2"><strong>Article 1</strong></h4>
                    <h5 class="mt-2"><strong>1.1 Objet</strong></h5>
                    <p>
                        QENIUM, agissant en tant que éditeur de logiciel, met à la disposition du Client un logiciel de
                        gestion d’entreprise lui permettant de gérer l’activité de son entreprise; gestion de la
                        relation client, gestion de produits et de stock, gestion de projets, gestion des ressources
                        humaines … Le Client peut créer son Compte EDIQIA, mettre à jour son profil, consulter et gérer
                        ses données via la solution EDIQIA.
                    </p>

                    <h5 class="mt-2"><strong>1.2 Le Service EDIQIA</strong></h5>
                    <p>
                        Le service n’a pour objet et ne comprend que des modules de suivi et d’aide à la décision
                        destinés au Client, à l’exclusion notamment de tout autre aspect commercial ou fonctionnel lié
                        au processus de vente du Client, tels que par exemple la création et l’hébergement du catalogue
                        de produits, la livraison de marchandises ou la gestion financière des transactions du Client.
                    </p>

                    <h4 class="mt-2"><strong>Article 2 – Adresse du Compte EDIQIA</strong></h4>

                    <p>
                        Le Compte EDIQIA du Client est accessible via un Sous domaine du nom de domaine www.ediqia.com.
                        Le Client choisi lui-même son Sous domaine et reçoit un mail lors de son inscription confirmant
                        l’adresse de son application. Les mentions « login », « s’abonner », « accéder à mon compte », «
                        créer un compte », etc., sont des liens clairement mentionnés sur le site EDIQIA, mais dont les
                        libellés peuvent changer à la discrétion de QENIUM. L’adresse du Site EDIQIA (www.ediqia.com)
                        ainsi que toutes les autres adresses reprises dans les Conditions Générales sont données à titre
                        indicatif et peuvent être modifiées. En ce cas, le Client en sera averti par écrit au plus tard
                        15 jours calendaires à l’avance.
                    </p>

                    <h4 class="mt-2"><strong>Article 3 – Création et gestion du Compte EDIQIA</strong></h4>
                    <p><strong>3.1.</strong> QENIUM se charge de la création du compte EDIQIA pour le Client. Ce dernier
                        peut également créer son propre compte depuis le site www.ediqia.com. Le Client choisit lui-même
                        son Sous domaine. Si le Sous domaine choisi par le Client est déjà utilisé par un autre Client,
                        il en sera averti par QENIUM au cours du processus de création du Compte EDIQIA. Il lui sera
                        alors demandé de choisir un autre Sous domaine disponible.
                        Lorsque le choix du Sous domaine est effectif, le Client agrée par QENIUM reçoit son mot de
                        passe par e-mail. Il dégage alors QENIUM de toute responsabilité en cas d’interception ou
                        réutilisation par un Tiers du mot de passe ainsi communiqué. Le Client devra modifier son mot de
                        passe à la première utilisation et pourra ensuite le modifier autant de fois qu’il le voudra via
                        le menu de gestion de son Compte EDIQIA. Le Sous domaine, le login et le mot de passe permettent
                        au Client, via son Compte du Site EDIQIA, de suivre les étapes de configuration de son Compte et
                        d’utiliser le Service.
                    </p>

                    <p><strong>3.2.</strong> Le Client reconnait avoir librement choisi le logiciel de Navigation pour
                        accéder au Site EDIQIA. Ce choix relève de sa seule responsabilité et ne saurait engager celle
                        de QENIUM à quelque titre que ce soit.
                    </p>

                    <h4 class="mt-2"> <strong> Article 4 – Prise en main du Service EDIQIA</strong></h4>
                    <p>
                        Le Client peut utiliser son Compte EDIQIA dès sa création. Il aura accès depuis son compte à la
                        documentation nécessaire pour la prise en main du Logiciel. Si le Client opte pour une formation
                        au Service EDIQIA, une documentation détaillée est disponible sur demande adressée à QENIUM.
                    </p>

                    <h4 class="mt-1"><strong>Article 5 – Sécurité et contrôle des données</strong> </h4>
                    <p>
                        <strong>5.1.</strong> Le Client reconnait l’importance du respect des règles de sécurité. Entre
                        autres, le Client doit en tout temps :
                        • Protéger tous ses mots de passe et les changer régulièrement, notamment le mot de passe
                        d’accès au Compte EDIQIA <br>
                        • Protéger l’accès à ses serveurs et à ses ordinateurs, ainsi qu’à son infrastructure technique
                        en général, notamment à l’aide de firewall et d’antivirus. <br>


                        <strong>5.2.</strong> QENIUM recommande fortement aux Clients de mettre en place le maximum de
                        mécanismes de contrôle, et QENIUM se réserve le droit de limiter l’accès au Compte EDIQIA (types
                        d’opérations permises, nombre maximum d’appels par période, tentatives de hacking etc.), si elle
                        juge les contrôles du Client insuffisants. Les mécanismes de contrôles et/ou de suivis utilisés
                        et/ou l’activation ou non des options de sécurité mise à la disposition du Client par EDIQIA,
                        relève de son libre choix et ne sauraient en aucune manière engager la responsabilité de QENIUM.

                    </p>
                    <h4 class="mt-1"><strong> Article 6 – Licence sur les Logiciels et autres droits de propriété
                            intellectuelle</strong></h4>
                    <p>
                        <strong>6.1.</strong> QENIUM octroie au Client, qui l’accepte, une licence non exclusive
                        d’utiliser, dans la mesure strictement nécessaire pour la création et la gestion de son Compte
                        EDIQIA selon la formule choisie et dans les limites et conditions énoncées dans le Contrat
                        Client, (i) les Logiciels mis à sa disposition via le Site EDIQIA et (ii) tous les autres droits
                        de propriété intellectuelle liés au service EDIQIA. QENIUM se réserve le droit de modifier à
                        tout moment les Logiciels, de les adapter, de mettre à disposition du Client une nouvelle
                        version et de modifier les fonctionnalités et caractéristiques des Logiciels. <br>

                        <strong>6.2.</strong> La licence décrite en article 6.1 est personnelle et incessible sauf
                        accord préalable écrit de QENIUM. La licence ne confère aucun droit de propriété quelconque,
                        lequel appartient exclusivement à QENIUM. La licence ne donne aucun droit sur le code source ou
                        le Logiciel décompilé, sauf les prérogatives conférées par les lois nationales concernant la
                        protection juridique des programmes d’ordinateur. Les Logiciels ne peuvent être utilisés que par
                        le Client, à l’exclusion de toute autre personne, ce qui exclut notamment la vente, la location,
                        la sous-exploitation, le leasing, etc., des Logiciels ou Service EDIQIA.
                    </p>
                    <h4 class="mt-1"><strong>Article 7 – Hébergement par QENIUM</strong></h4>
                    <p>
                        <strong>7.1.</strong> QENIUM s’engage à héberger le Compte EDIQIA du Client. <br>
                        <strong> 7.2.</strong> Le Client est tenu d’avertir QENIUM dans les plus brefs délais et de
                        manière exhaustive de toute circonstance ou fait concernant l’hébergement qui pourrait donner
                        lieu à une quelconque action ou plainte d’un Tiers à l’encontre de QENIUM. <br>
                        <strong>7.3.</strong> QENIUM peut effectuer toutes les opérations nécessaires à l’hébergement et
                        à la protection du Compte EDIQIA, notamment: l’exécution de copie de sauvegarde de son contenu.
                    </p>


                    <h4 class="mt-1"> <strong>Article 8 – Responsabilité, garanties et engagements</strong></h4>

                    <p>
                        En cas d’inexécution de ses obligations contractuelles, la responsabilité de QENIUM peut être
                        recherchée dans conditions et limites suivantes :
                    </p>

                    <p>
                        <strong>• Limitation de responsabilité dans son principe </strong> : QENIUM ne répond que des
                        violations graves et répétées des dispositions contractuelles, dont il est démontré qu’elles lui
                        sont directement imputables.
                        La transmission de données sur internet étant complexe et impliquant de nombreuses parties
                        intervenantes, il est ainsi expressément reconnu que la responsabilité de QENIUM ne pourra être
                        recherchée que du fait de circonstances se situant sur la partie d’infrastructure qu’elle gère
                        et sur laquelle elle exerce un pouvoir de contrôle. Aucune responsabilité de quelque sorte que
                        ce soit ne pourra dès lors être recherchée pour des faits dont l’origine se situe en amont ou en
                        aval. Notamment, tout dommage issu d’un défaut de connectivité dont l’origine est en amont ou en
                        aval (par exemple, défaut de la part de l’Acquéreur, de connectivité Internet, etc.) ne saurait
                        engager la responsabilité de QENIUM. Il ne peut être dérogé à la présente clause. <br>

                    </p>

                    <p>

                        <strong>• Exclusion de certains dommages </strong> : QENIUM n’est pas responsable (et ce quel
                        que soit le fondement de la responsabilité : contractuel, délictuel, ou pour violation d’une
                        quelconque obligation légale ou règlementaire) et n’indemnisera en aucun cas les préjudices ou
                        dommages indirects, pertes de chiffre d’affaires, pertes de clientèles, pertes de revenus
                        escomptés, atteintes à la réputation, préjudices subséquents ou tous préjudices spéciaux, ainsi
                        que les dommages par répercussion et les dommages immatériels ou en particulier tout dommage
                        dont QENIUM ne peut maitriser ni la survenance ni l’évolution.
                        Cette exclusion d’indemnisation est applicable quel que soit le caractère prévisible ou non de
                        ces préjudices dans leur principe et leur ampleur, lors de la conclusion du Contrat Client, et
                        même si QENIUM a été avisée de la possibilité de survenance de tels préjudices .<br>

                    </p>


                    <p>
                        <strong>• Limitation de responsabilité dans son montant – limitation de réparation </strong>:
                        Toute responsabilité de QENIUM non exclue par les Conditions Générales, ouvrira droit au
                        paiement de dommages intérêts limités au montant de la facture adressée au Client pour la
                        période précédant immédiatement l’évènement générateur de responsabilités.
                        Cette nécessaire limitation de responsabilité de QENIUM est mise en place afin de maintenir un
                        principe de proportionnalité entre les dommages-intérêts réclamés, les prix pratiqués par QENIUM
                        et le comportement répréhensible reproché. <br>

                    </p>

                    <p>

                        <strong> • Décès, dommage corporel et fraude</strong> : la responsabilité de QENIUM au titre de
                        Conditions Générales ne peut être limitée en cas de mort, de dommages corporels, de vol ou de
                        faute lourde.
                        Sauf dérogation écrite expresse figurant dans tout autre contrat liant les parties, la
                        responsabilité de QENIUM est exclusivement régie par le présent article. Elle ne peut ainsi être
                        engagée en vertu des Conditions Générales pour un défaut de niveau de service quel qu’il soit.
                        Une telle responsabilité ne peut être encourue qu’en cas de souscription d’un SLA et dans les
                        limites qui y sont expressément indiquées.
                    </p>

                    <h5 class="mt-1"> <strong>8.1. Garanties relatives à l’hébergement et au fonctionnement</strong>
                    </h5>

                    <p>
                        <strong>8.1.1.</strong> QENIUM assure l’hébergement et le fonctionnement du Service EDIQIA dans
                        l’environnement matériel et logiciel qu’elle aura librement choisi pour héberger le Compte
                        EDIQIA. <br>
                    </p>

                    <p>
                        <strong> 8.1.2.</strong> La protection du Compte EDIQIA est assurée par des moyens adéquats et
                        raisonnables choisis et mis en oeuvre par QENIUM dans la cadre d’une obligation de moyen. Le
                        Client administrant seul le Compte EDIQIA demeure toutefois entièrement responsable pour le
                        choix et la gestion du Sous domaine, des données de connexion (crédentiels) des Utilisateurs du
                        Client et des mots de passe liés.
                        Le Client reconnait qu’il est techniquement impossible en l’état actuel des connaissances de
                        garantir une sécurité sans failles des données échangées sur le réseau ouvert Internet. QENIUM
                        déclare avoir pris pour sa part toutes les dispositions raisonnables et nécessaires pour assurer
                        un service sécurisé. Le Client demeure de son coté responsable de la sécurité des données qu’il
                        transmet via Internet. <br>
                        QENIUM ne pourra dès lors être tenue responsable de dommages ou pertes dues à un défaut ou une
                        violation de la protection ou de l’intégrité des données nécessaires quel que soit le
                        responsable de ce défaut ou de cette violation (sauf en cas de vol ou de faute lourde de QENIUM
                        ou de ses employés). Le Client garantit à ce titre QENIUM contre tout recours qui serait intenté
                        de ce fait par un Tiers, et ce compris le clients du Client. <br>
                    </p>

                    <p>
                        <strong>8.1.3.</strong> Le Client administre sous sa seule responsabilité son Compte EDIQIA,
                        dont il assure la bonne gestion.
                        QENIUM n’est responsable d’aucune perte ni dommage subi par le Client (tels que perte
                        d’exploitation, perte de données, etc.) en raison du mauvais fonctionnement du Service EDIQIA,
                        causé par une modification apportée à celui-ci, volontairement ou non, par le Client ou par
                        toute personne autre que QENIUM et ses employés, sauf si une telle modification aurait été
                        effectuée en totale conformité avec les instructions expresses de QENIUM. <br>
                    </p>


                    <p>
                        <strong>8.1.4.</strong> Le Client est seul responsable, si par suite d’une fraude ou d’une
                        négligence grave dans l’utilisation de son Compte EDIQIA ou d’un de ses éléments (login ou mot
                        de passe par exemple) il cause un dommage aux autres possesseurs d’un Compte EDIQIA. Les
                        fichiers log de QENIUM traçant les accès à son (ses) serveur(s) http et à son (ses) serveur(s)
                        QENIUM font foi à ce titre. Le Client garantit alors intégralement QENIUM de toutes
                        condamnations prononcées à la requête de tout Tiers du fait du préjudice que ce dernier aurait
                        subi, telles que des interruptions ou des difficultés d’accès à leur Compte EDIQIA. <br>
                    </p>

                    <p>
                        <strong>8.1.5.</strong> Sans autorisation préalable, expresse et écrite de QENIUM, le Client ne
                        permettre à un Tiers d’utiliser tout ou partie de son Compte EDIQIA. <br>
                    </p>

                    <p>
                        <strong>8.1.6.</strong> QENIUM n’est pas responsable des dommages subis par le Client ou des
                        Tiers résultant d’un cas de force majeure ou d’un évènement sur lequel QENIUM n’a aucune
                        maîtrise. <br>
                    </p>

                    <h5 class="mt-1"> <strong>8.2. Déclarations et garanties relatives au contenu du Compte
                            EDIQIA</strong></h5>

                    <p>
                        <strong>8.2.1.</strong> Sauf s’agissant des éléments fournis par QENIUM, le Client reste
                        exclusivement responsable du contenu de son Compte EDIQIA, lequel comprend les paramètres de
                        configuration du Compte EDIQIA, et les données elles-mêmes (ci-après dénommer « Contenu »). Le
                        Client est libre de tenir le Contenu à jour ainsi qu’à le maintenir exact, complet et actualisé
                        en permanence.<br>
                    </p>

                    <p>
                        <strong>8.2.2.</strong> Le Client garantit que (i) le Contenu de son Compte EDIQIA n’enfreint
                        pas les droits de propriété intellectuelle de Tiers, le droit à la protection de la vie privée,
                        le droit de la personne, le droit de représentation ou tout autre droit d’un Tiers quelconque;
                        (ii) le Contenu n’est pas contraire aux bonnes mœurs, à l’ordre public ni aux codes de conduites
                        applicables; (iii) les logiciels et les fichiers fournis par le Client ne comportent pas de
                        virus; (iv) le Contenu n’est contraire ni aux lois, ni aux règlements applicables, tels que les
                        lois sur les pratiques et usages de commerce.<br>
                    </p>


                    <h5 class="mt-1"> <strong>8.3. Garanties relatives au Client – Engagements du Client</strong></h5>

                    <p>
                        <strong>8.2.2.</strong> Le Client garantit que (i) le Contenu de son Compte EDIQIA n’enfreint
                        pas les droits de propriété intellectuelle de Tiers, le droit à la protection de la vie privée,
                        le droit de la personne, le droit de représentation ou tout autre droit d’un Tiers quelconque;
                        (ii) le Contenu n’est pas contraire aux bonnes mœurs, à l’ordre public ni aux codes de conduites
                        applicables; (iii) les logiciels et les fichiers fournis par le Client ne comportent pas de
                        virus; (iv) le Contenu n’est contraire ni aux lois, ni aux règlements applicables, tels que les
                        lois sur les pratiques et usages de commerce.<br>
                    </p>

                    <p>
                        <strong>8.3.1.</strong> Le Client garantit avoir la capacité juridique ainsi que toutes les
                        autorisations nécessaires afin de vendre ses produits/services en utilisant le Compte
                        EDIQIA.<br>
                    </p>

                    <p>
                        <strong>8.3.2.</strong> Le Client garantit d’utiliser son Compte EDIQIA à des fins strictement
                        professionnelles. Il garantit dès lors qu’il n’agit pas en tant qu’intermédiaire.<br>
                    </p>

                    <p>
                        <strong>8.3.3.</strong> Le Client s’engage à mettre en place une gestion efficace des questions
                        de sécurité, conformément à l’article 5.1 repris ci-dessus.<br>
                    </p>

                    <p>
                        <strong>8.3.4.</strong> Le Client s’engage à effectuer un contrôle régulier efficace et
                        suffisant de ses appareils, conformément à l’article 5.2 repris ci- dessus.<br>
                    </p>

                    <p>
                        <strong>8.3.5.</strong> Le Client s’engage à utiliser le service EDIQIA conformément à la
                        documentation fournie. Il s’engage à ne pas perturber le service EDIQIA, notamment par une
                        utilisation du service à d’autres fins que celles strictement prévues dans le Contrat Client.
                        Par exemple sont interdites les interrogations systématiques du système pour tester la
                        disponibilité du service ou les interrogations répétitives inutiles du système pour connaitre
                        l’état de données inexistantes ou dont le statut n’évolue plus.<br>
                    </p>


                    <h4 class="mt-1"> <strong> Article 9 – Prix – Paiements</strong></h4>

                    <p>
                        <strong>9.1.</strong> Le Service EDIQIA est facturé trimestriellement, semestriellement ou
                        annuellement au Client.
                        Cette fréquence de facturation peut être modifiée par QENIUM moyennant un préavis d’un mois aux
                        Clients. L’abonnement et les options souscrites sont facturés d’avance au début de la
                        période.<br>
                    </p>

                    <p>
                        <strong>9.2.</strong> Sauf mention expresse contraire dans le Contrat Client, les factures
                        émises par QENIUM sont payables par chèque, par carte de crédit ou tout autre moyen de paiement,
                        que le Client s’interdit de révoquer pendant toute la durée du contrat. Si les débits devaient
                        être rejetés par la banque du Client, ce dernier s’engage à régler immédiatement ses
                        factures.<br>
                    </p>


                    <p>
                        <strong>9.3.</strong> Sauf mention expresse contraire dans le Contrat Client, les factures
                        émises par QENIUM sont payables dans un délai de 15 jours calendaires à compter de la date
                        d’émission de la facture. En cas de retard de paiement, un rappel sera envoyé au Client. Dans ce
                        cas, des frais de rappel d’un montant de 15 000 FCFA seront de plein droit ajoutés à la facture
                        suivante adressée au Client, sans autre préavis. Ces frais de rappel ne pourront être supprimés
                        que si (i) cet incident est le premier pour ce Client et (ii) la situation est entre-temps
                        régularisée.<br>
                    </p>

                    <p>
                        <strong>9.4.</strong> En cas de retard de paiement important, c’est-à-dire de plus de 30 jours
                        calendaires après la date d’échéance de la facture, ou en cas de révocation du mécanisme de
                        débit automatique mentionné à l’article 10.3, une procédure contentieuse sera mise en œuvre.
                        Dans ce cas, le Compte EDIQIA du Client pourra être résilié par QENIUM sans préavis, les frais
                        mentionnés à l’article 11.3 et les frais de recouvrement de créance seront intégralement dus et
                        mis en compte. Dans tous les cas des intérêts de retard au taux de 12% l’an seront imputés au
                        Client depuis le jour d’émission des factures. Les intérêts seront décomptés de manière
                        journalière avant aussi bien qu’après toute décision judiciaire éventuelle. <br>
                    </p>

                    <h4 class="mt-1"> <strong>Article 10 – Durée – Résiliation</strong></h4>

                    <p>
                        <strong>10.1.</strong> Sauf mention expresse contraire, le Contrat Client entre en vigueur au
                        jour de l’activation du Compte EDIQIA du Client. En cas de Proposition de Contrat Client, QENIUM
                        active en principe le Compte EDIQIA dès réception de la Proposition de Contrat Client signé par
                        le Client et de tous autres documents ou informations estimés nécessaires par QENIUM ; QENIUM se
                        réserve néanmoins le droit discrétionnaire d’activer ou non le Compte EDIQIA. <br>
                    </p>

                    <p>
                        <strong>10.2.</strong>Sauf mention expresse contraire, le Contrat Client est conclu à sa date
                        d’entrée en vigueur pour une durée initiale ferme de 30 jours. A l’issue de cette période
                        initiale, il est renouvelé tacitement aux termes et conditions identiques pour des durées
                        successives selon l’abonnement du client (six mois ou une année).
                        Le Contrat Client peut être résilié à tout moment par le Client. Son compte sera suspendu dès
                        l’arrêt des paiements. Après relances, ses données seront conservées pendant une durée de 6 mois
                        avant d’être détruites. <br>
                    </p>

                    <p>
                        <strong>10.2.1.</strong> en cas de non-respect par l’autre partie de l’une quelconque de ses
                        obligations substantielles convenues au Contrat Client, un mois après l’envoi d’une mise en
                        demeure par lettre recommandée avec avis de réception ; <br>
                    </p>

                    <p>
                        <strong>10.2.2.</strong> en cas de faillite, insolvabilité, redressement ou liquidation
                        judiciaire, placement sous mandat d’administration, concordat définitif ou plan de règlement
                        amiable des créanciers, entré en vigueur d’un moratoire, saisi sur les biens de l’autre partie,
                        ou saisie-arrêt conservatoire dans les mains de QENIUM par un créancier du Client.<br>
                    </p>

                    <h4 class="mt-1"> <strong> Article 11 – Confidentialité</strong></h4>
                    <p>
                        Par le biais de l’inscription sur le site www.ediqia.com, QENIUM collecte vos données
                        personnelles en vue de traiter les opérations de gestion de votre Compta EDIQIA. Ce traitement a
                        fait l’objet d’une déclaration / demande d’autorisation auprès de l’ordre des experts comptables
                        de côte d’ivoire. Les données personnelles collectées peuvent être transmises.
                        Vous pouvez vous adresser à l’adresse email support@ediqia.com pour exercer vos droits d’accès,
                        de rectification et d’opposition conformément aux dispositions de la loi 09-08. <br>
                    </p>

                    <p>
                        <strong>11.1.</strong> Doivent être considérées comme confidentielles, toutes les données, quels
                        que soient leur support et leur nature, relatives au Client ou à QENIUM et indiquées comme
                        confidentielles, ainsi que les données qui ne sont pas mentionnées comme telles, mais qui
                        doivent raisonnablement être perçues comme telles en raison de leur support, nature ou contenu.
                        Chaque partie reste propriétaire des données qu’elle a mises à la disposition de l’autre
                        partie.<br>
                    </p>

                    <p>
                        <strong>11.2.</strong>
                        Chaque partie s’engage à prendre toutes les mesures nécessaires pour préserver le caractère
                        confidentiel des données qui le sont au sens des Conditions Générales, et en particulier : <br>
                        <strong>•</strong> à utiliser l’information confidentielle uniquement pour l’exécution de ses
                        obligations résultant du Contrat Client, à l’exclusion de tout autre usage <br>
                        <strong>•</strong> à communiquer les données confidentielles fournies dans le cadre de
                        l’exécution du Contrat Client, en tout ou en partie, oralement ou par écrit, uniquement au
                        personnel ou aux représentants des parties qui doivent en être informées dans le cadre de la
                        collaboration prévue dans Le Contrat Client, ou aux autorités légales sur leur demande écrite;
                        <br>
                        <strong>•</strong> à ne pas divulguer à des Tiers les données confidentielles obtenues, sauf
                        autorisation écrite préalable de l’autre partie; <br>
                        <strong>•</strong> à restituer à l’autre partie, sur simple demande et dans les plus brefs
                        délais, tout document, copie, note, enregistrement, mémorandum ou autres documents émis par
                        cette dernière et qui comporteraient des données confidentielles.<br>
                    </p>

                    <p>
                        <strong>11.3.</strong> L’obligation de confidentialité telle que décrite ci-dessus reste en
                        vigueur pendant toute la durée du Contrat Client. De plus, elle subsiste durant cinq ans après
                        la fin de la période de test ou du Contrat Client, quelle qu’en soit la cause.<br>
                    </p>

                    <p>
                        <strong>11.4.</strong> Sont à considérer comme confidentiels, sans nécessité de mention
                        spécifique :
                    <ul>
                        <li>Les données financières </li>
                        <li>Les termes et conditions du Contrat Client</li>
                        <li>Les manuels et guides d’utilisation des produits et services EDIQIA.</li>
                    </ul>
                    </p>


                    <p>
                        <strong>11.5.</strong> Dans le cadre de l’intégration de EDIQIA avec des services tiers, comme
                        Google, Microsoft ou Mailchimp, une demande d’accès sera demandée au Client afin d’autoriser la
                        plateforme à envoyer des mails ou interagir avec des services tiers.
                        Ces accès seront stockés de façon cryptée dans notre base de donnée et ne peuvent être utilisées
                        que par le Client à travers la plateforme.
                        Le Client a la possibilité à tout moment de révoquer les autorisations aux services tiers et ce
                        directement depuis la Plateforme.<br>
                    </p>



                    <h4 class="mt-1"> <strong> Article 12 – Dispositions générales</strong></h4>

                    <p>
                        <strong>12.1.</strong> En cas de problèmes techniques relatifs à son Compte EDIQIA, le Client
                        peut accéder au Site EDIQIA pour consulter en ligne la documentation et les FAQ disponibles, ou
                        créer un ticket d’incident via l’onglet « Tickets » disponible dans le menu de son Compte
                        EDIQIA, envoyer un email à support@ediqia.com, ou prendre contact avec le helpdesk de QENIUM par
                        téléphone (n° de téléphone de QENIUM disponible sur le Site EDIQIA, dans la page « Contact »)
                        aux heures d’ouverture: 9.00h – 18.00h – heure locale, pendant les jours ouvrables, les samedis,
                        dimanches et jours fériés légaux n’étant pas considérés comme ouvrable.
                        Le support non électronique (téléphone, meetings, etc.) est gratuit aux conditions suivantes :
                        <br>

                    <ul>
                        <li>le type d’abonnement choisi par le Client ne doit pas exclure la gratuité du support;</li>
                        <li>le Client doit maîtriser le Service EDIQIA et doit avoir préalablement recherché la cause du
                            problème dans la documentation disponible ; le problème doit bien provenir du Service
                            EDIQIA, et non d’un système en amont ou en aval.
                            Si ces conditions ne sont pas remplies, le support pourra être facturé au Client.</li>
                    </ul>
                    </p>



                    <h5 class="mt-1"> <strong>12.2. Force majeure</strong></h5>

                    <p>
                        <strong>12.2.1.</strong> Les parties ne seront pas tenues responsables des retards ou
                        manquements dans l’exécution du Contrat Client lorsque ces retards ou manquements sont la
                        conséquence de faits ou de circonstances raisonnablement indépendants de leur volonté,
                        imprévisibles et irrésistibles.
                        Les parties conviennent que des virus ou autres fautes résultant d’un accès non autorisé aux
                        Logiciels ou au Site EDIQIA ou de toute forme de « hacking » (ou piratage informatique, incluant
                        notamment les « DOS attacks »), doivent être considérés comme des cas de force majeure.<br>
                    </p>


                    <p>
                        <strong>12.2.2.</strong> La partie qui invoque de tels faits ou circonstances, en avisera
                        l’autre partie immédiatement et par écrit et mettra tout en œuvre pour éviter de tels cas de
                        force majeure, et réduire au maximum leur durée. Elle informera également l’autre partie de la
                        cessation des faits ou circonstances.<br>
                    </p>

                    <h5 class="mt-1"> <strong>12.3. Administration de la preuve</strong></h5>

                    <p>
                        <strong>12.3.1.</strong> QENIUM et le Client conviennent qu’une communication peut valablement
                        s’effectuer par e-mail, sauf stipulation contraire dans le Contrat Client.<br>
                    </p>

                    <p>
                        <strong>12.3.2</strong> QENIUM et le Client reconnaissent que les informations relatives aux
                        communications et contrats, qui sont enregistrées par QENIUM sur un support durable et non
                        modifiable, ont force probante jusqu’à preuve du contraire.<br>
                    </p>

                    <p>
                        <strong>12.3.3.</strong> Les conventions que le Client aurait conclues sur ou via Internet avec
                        des prestataires de services qui appliquent d’autres méthodes de preuve contractuelles ne sont
                        pas opposables à QENIUM.<br>
                    </p>

                    <h5 class="mt-1"> <strong>12.4. Modification des conditions générales</strong></h5>
                    <p>
                        La version des conditions générales en vigueur est à tout moment consultable sur le Site EDIQIA.
                        Le Client sera informé de toute modification des conditions Générales par une mention apparente
                        dans son back-office et sur sa facture mensuelle respective.
                        QENIUM peut modifier à tout moment tout ou partie des Conditions Générales dans la mesure où ces
                        modifications mineures ne concernent que des clauses non essentielles de celles-ci et n’affecte
                        pas les obligations fondamentales des parties. Ces modifications entreront en vigueur à compter
                        de l’information du Client.
                        Si les modifications affectent les obligations essentielles du Contrat Client, la version des
                        conditions générales en vigueur avant la modification restera en vigueur jusqu’au terme de la
                        période annuelle en cours. A cette date le Contrat Client sera renouvelé avec application des
                        nouvelles conditions générales que le Client est réputé avoir acceptées à défaut de résiliation
                        de son Contrat Client dans un délai d’un mois à compter de la réception de l’email l’avisant de
                        la mise en place de nouvelles conditions générales. Cette résiliation intervient sans frais.
                        Exceptionnellement, QENIUM peut modifier unilatéralement avec Application immédiate des éléments
                        essentiels de ces conditions générales à condition d’établir que : <br>


                    <ul>
                        <li>les modifications sont nécessaires suite à de nouvelles conditions générales imposées à
                            QENIUM par un Tiers tel que (i) une société de carte de crédit, une Institution Financière,
                            un fournisseur de hard- ou software, etc… (liste indicative et non exhaustive) ou (ii) une
                            autorité légale;

                        </li>
                        <li>
                            sans ces modifications, l’activité de QENIUM est impactée négativement ou serait rendue
                            impossible.
                            Dans ce cas, la faculté de résiliation telle que prévue au paragraphe précédent est
                            maintenue au profit du Client.
                        </li>
                    </ul>
                    </p>


                    <h5 class="mt-1"> <strong>12.5. Listing de clientèle et promotions</strong></h5>
                    <p>
                        Par la conclusion du Contrat Client, le Client accepte que son nom figure sur la liste des
                        clients de QENIUM. Cette liste peut être librement utilisée par QENIUM dans ses efforts
                        commerciaux. Par ailleurs, QENIUM peut reprendre, dans une rubrique du type « ce qu’ils en
                        disent », tout ou partie d’un message non confidentiel adressé par le Client et dont elle peut
                        déduire la satisfaction du Client. Préalablement, QENIUM enverra au Client le texte qu’elle
                        entend réutiliser. L’absence de réaction du Client dans les cinq jours calendaires de cet envoi
                        vaut accord. <br>
                    </p>


                    <h4 class="mt-1"> <strong>Article 13 – Droit applicable</strong></h4>
                    <p>
                        Le Contrat Client est régi par le droit de l’état dans lequel se trouve le siège social de la
                        société QENIUM partie au Contrat Client.
                    </p>


                    <h4 class="mt-1"> <strong>Article 14 – Règlement des litiges</strong></h4>
                    <p>
                        <strong>14.1.</strong> Les parties s’engagent à tout mettre en œuvre pour parvenir à un accord à
                        l’amiable pour tout litige relatif au Contrat Client.<br>
                    </p>

                    <p>
                        <strong>14.2.</strong> À défaut d’accord amiable, tout litige sera soumis aux tribunaux de
                        commerce compétents au lieu du siège social de la société QENIUM partie au Contrat Client.
                        Le Client reconnaît avoir pris connaissance des conditions générales et des conditions
                        particulières et déclare les accepter sans réserve.<br>
                    </p>



                      
                    </div>

                    
                    <div>
                      <footer class="container">
                       COPYRIGHT  © {{ new Date().getFullYear() }} <a href="//qenium.com">Qenium,</a> Tous
                      droits réservés.<br />
                    </footer>
                    </div>
                  </b-template>
                </b-modal>
              </b-col>
              <!-- end popup condition génerale de vente -->


              <b-button
                disabled
                variant="primary"
                block
                type="submit"
                class="bg-indigo"
                v-if="loading"
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </b-button>
              <b-button
                variant="primary"
                block
                type="submit"
                class="bg-indigo"
                v-else
                @click.prevent="save"
              >
                {{ $t("message.login.register") }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>{{ $t('message.login.is_login') }}</span>
            <b-link :to="{ name: 'login' }">
              <span>&nbsp; {{ $t("message.login.btn_login") }} </span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BModal,
  VBModal,
  BForm,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import { required, email } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import URL, { APP_HOST } from "@/views/pages/request";
import axios from "axios";
import setAuthHeader from "../../../../auth/jwt/token";
import vSelect from "vue-select";
import { VueTelInput } from "vue-tel-input";
import Ripple from "vue-ripple-directive";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import CryptoJS from "crypto-js";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { qCrypt, qCryptJson, qDecryptedJson } from "@/services/qeCrypt";
import { toast_reception } from "@/utils/qToast";
export default {
  components: {
    BInputGroupPrepend,
    CryptoJS,
    VueGoogleAutocomplete,
    VueTelInput,
    vSelect,
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BModal,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    VBModal,
    ValidationObserver,
    setAuthHeader,
  },
  mixins: [togglePasswordVisibility],
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      logData: "",
      validePresentPassword: false,
      presentPassword: "",
      phone: "",
      status: "",
      nom: "",
      prenom: "",
      contactFinal: "",
      indicatifFinal: "",
      email: "",
      password: "",
      contact: "",
      valideNom: false,
      validePrenom: false,
      valideContact: false,
      valideEmail: false,
      valideContactNumber: false,
      validePassword: false,
      valideStatus: false,
      sideImg: require("@/assets/images/pages/register-v2.svg"),
      erreur: false,
      required,
      email,
      loading: false,
      address: "",
      ad: "",
      APP_HOST: APP_HOST,
      placechanged: "",
      localisation: "",
      permissions: [],
      valideEmailExist: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/register-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  mounted() {
    if (localStorage.getItem("token")) {
      this.$router.push({ name: "home" });
    }
    document.title = "Nouveau compte - Ediqia";
    google.maps.event.addDomListener(window, "load", initialize);
  },
  methods: {
    redirection() {
      location.assign(APP_HOST + "/cgu.html");
    },
    redirection1() {
      location.assign(APP_HOST + "/politiqueConfidentialite.html");
    },
    redirection2() {
      location.assign(APP_HOST + "/cgv.html");
    },

    validatePresentPassword() {
      if (!this.presentPassword) {
        this.validePresentPassword = true;
      } else {
        this.validePresentPassword = false;
      }
    },
    initialize() {
      var input = document.getElementById("autocomplete_search");
      var autocomplete = new google.maps.places.Autocomplete(input);
      autocomplete.addListener("place_changed", function () {
        var place = autocomplete.getPlace();
        // place variable will have all the information you are looking for.
        $("#lat").val(place.geometry["location"].lat());
        $("#long").val(place.geometry["location"].lng());
        this.localisation = place;
        localStorage.setItem("locali", JSON.stringify(place));
        // console.log(this.address)
      });
    },
    isDigit(str) {
      return /^\d+$/.test(str);
    },

    /**
     *
     * @param {string} email
     */
    async save() {
      this.validateStatus();
      this.address = JSON.parse(localStorage.getItem("place"));

      try {
        this.contactFinal = this.contact.country.nationalNumber;
        this.validateEmail();
        this.validateContact();
        this.validateNom();
        this.validatePrenom();
        this.validatePassword();
        const config = {
          headers: {
            Accept: "application/json",
          },
        };
        if (this.erreur === false && this.status === true) {
          this.indicatifFinal = "+" + "" + this.contact.country.dialCode;
          const data = {
            nom: this.nom,
            prenoms: this.prenom,
            contact: this.contactFinal,
            email: this.email,
            password: this.password,
            indicateur: this.indicatifFinal,
            localisation: JSON.parse(localStorage.getItem("locali")),
          };
          localStorage.removeItem("locali");
          console.log(data);
          this.loading = true;
          try {
            await axios.post(URL.REGISTER, data, config).then((response) => {
              if (response.data) {
                this.userData = response.data;
                if (this.userData.present) {
                  this.valideEmailExist = true;
                  this.loading = false;
                } else {
                  toast_reception(
                    this,
                    "success",
                    "top-right",
                    "Votre compte a été créé avec succès"
                  );

                  this.valideEmailExist = false;
                  setAuthHeader(response.data.token);
                  localStorage.setItem("token", this.userData.token);
                  if (localStorage.getItem("token")) {
                    localStorage.setItem(
                      "qIsNotAuthenticated",
                      qCrypt("isAuthenticated")
                    );
                    localStorage.setItem("qhze_001", qCrypt("true"));
                    this.$router.push("/entreprise");
                  }
                }
              }
            });
          } catch (error) {
            this.loading = false;
            console.log(error);
          }
        } else {
          this.error_global = true;
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    validateEmail() {
      // valid email regex pattern
      const emailPattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
      if (!this.email.match(emailPattern)) {
        this.valideEmail = true;
        this.erreur = true;
      } else {
        this.valideEmail = false;
        this.erreur = false;
      }
    },
    validateContact() {
      this.contactFinal = this.contact.formatted;
      if (!this.contactFinal) {
        this.valideContact = true;
        this.erreur = true;
      } else {
        this.valideContact = false;
        this.erreur = false;
      }

      if (this.contactFinal && this.contactFinal.length < 8) {
        this.valideContactNumber = true;
        this.erreur = true;
      } else {
        this.valideContactNumber = false;
        this.erreur = false;
      }
    },
    validatePassword() {
      if (!this.password || this.password.length < 8) {
        this.validePassword = true;
        this.erreur = true;
      } else {
        this.validePassword = false;
        this.erreur = false;
      }
    },
    validateNom() {
      if (!this.nom) {
        this.valideNom = true;
        this.erreur = true;
      } else {
        this.valideNom = false;
        this.erreur = false;
      }
    },
    validatePrenom() {
      if (!this.prenom) {
        this.validePrenom = true;
        this.erreur = true;
      } else {
        this.validePrenom = false;
        this.erreur = false;
      }
    },
    validateStatus() {
      if (this.status == false || !this.status) {
        this.valideStatus = true;
        this.erreur = true;
        console.log("selectione");
      } else {
        this.valideStatus = false;
        this.erreur = false;
      }
    },
    contactEntier(e) {
      this.contact = e;
    },
    changer(e) {
      // this.contact.country.name = e.name;
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.none {
  display: none;
}
.block {
  display: inline-block;
}
[dir] .vs__search,
[dir] .vs__search:focus {
  border: 1px solid transparent;
  border-left-color: transparent;
  border-left-style: solid;
  border-left-width: 1px;
  margin: 4px 0 0 0;
  margin-top: 4px;
  padding: 0 0px;
  background: none;
  box-shadow: none;
}
</style>
